import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import { Check } from "@styled-icons/octicons";
import { monthMapLongToShort } from "@utils/utils";

import { EditionCardProps } from "./types";
import {
  CardImageWrapper,
  CardImage,
  CardContent,
  CardLine,
  CardHeadline,
  CardTitle,
  CreatorContainer,
  ArtCardStack,
} from "./styled";

const EditionCardStack = styled(ArtCardStack)<{ isHighlighted: boolean }>`
  ${(props) =>
    props.isHighlighted &&
    css`
      box-shadow: 0px 0px 24px 0px rgba(31, 188, 255, 0.9),
        0px 4.2px 12.6px 0px rgba(0, 0, 0, 0.1);
    `}

  &:hover {
    border: 1px solid #1fbcff;
    cursor: pointer;
  }
`;

const MintMark = styled.div`
  background: #11c690;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  svg {
    fill: #000;
  }
`;

const EditionCard = ({
  year,
  month,
  imgurl,
  title,
  creatorName,
  creatorFlag,
  isHighlighted = false,
  hasMinted = false,
}: EditionCardProps) => {
  const navigate = useNavigate();

  const gotoEdition = () => {
    navigate(`/${year}/${month}`);
  };

  return (
    <EditionCardStack
      spacing={2}
      onClick={gotoEdition}
      isHighlighted={isHighlighted}
    >
      <CardImageWrapper>
        <CardImage imgurl={imgurl} />
      </CardImageWrapper>
      <CardContent>
        <CardLine>
          <CardHeadline>
            {month} {year}
          </CardHeadline>
          <CreatorContainer>
            {creatorName}{" "}
            {creatorFlag ? (
                creatorFlag != "SEA" ? (
                  <ReactCountryFlag
                    countryCode={creatorFlag}
                    svg
                    title={getName(creatorFlag)}
                  />
                ) : 
                `🌏`
            ) : (
              <></>
            )}
          </CreatorContainer>
        </CardLine>
        <CardLine>
          <CardTitle>{title}</CardTitle>
          {hasMinted ? (
            <MintMark>
              <Check size="16" />
            </MintMark>
          ) : (
            ""
          )}
        </CardLine>
      </CardContent>
    </EditionCardStack>
  );
};

export default EditionCard;
