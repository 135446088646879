import styled from "styled-components";
import {
  DeviceShowType,
  Spacer,
  Container,
  ContainerContent,
  VerticalStack,
} from "@components/Layout";
import { Title, DropHead as StyledDropHead } from "@components/Text";
import { Footer } from "@components/Footer";

const SingleContainerContent = styled(ContainerContent)`
  margin-top: 40px;
  max-width: 800px;

  a {
    text-decoration: none;
    font-family: "Mudial Regular", sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.fgColor};
    }
  }

  li {
    font-family: "Mudial Regular", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px;

    strong {
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 1px;
    }
  }
`;

const DropHead = styled(StyledDropHead)`
  margin-top: 40px;
  margin-bottom: 8px;
`;

const Terms = () => {
  return (
    <>
      <Container>
        <SingleContainerContent>
          <Spacer $deviceShowType={DeviceShowType.MOBILEONLY} />
          <VerticalStack>
            <Title>Collectr Terms of Service</Title>
            <p>Last Updated: June 19, 2024</p>
          </VerticalStack>

          <DropHead>Welcome to Collectr</DropHead>

          <p>By using Collectr, you agree to the following terms.</p>

          <DropHead>1. Overview of Collectr</DropHead>

          <p>
            Collectr enables users to mint NFTs. As the platform interacts with
            decentralized code hosted on the Base blockchain, there is no user
            registration, or custody of user funds, assets, or property. All
            transactions are executed via a smart contract, which automatically
            allocates payments among the designated parties as determined by the
            Collectr team.
          </p>

          <p>
            By using the platform and minting our NFTs, users agree to waive
            their right to pursue legal action against Collectr and its
            affiliates, acknowledging that all transactions are final and any
            disputes must be resolved through the platform's decentralized
            mechanisms. Users also acknowledge and accept the inherent risks
            associated with blockchain technology and smart contracts, including
            but not limited to, potential loss of funds and technical
            malfunctions.
          </p>

          <p>
            Collectr reserves the right to modify these terms at any time, with
            or without notice to users.
          </p>

          <DropHead>2. Definitions</DropHead>

          <p>
            <ul>
              <li>
                <strong>Platform:</strong> The Collectr website and its
                associated services.
              </li>
              <li>
                <strong>User:</strong> Anyone who uses the Collectr platform.
              </li>
              <li>
                <strong>Artist:</strong> The individual or entity that creates
                the Digital Artwork featured on the platform each month.
              </li>
              <li>
                <strong>Community:</strong> A group, project, or collective
                represented in the featured Digital Artwork each month.
              </li>
              <li>
                <strong>Community Member:</strong> A member from the featured
                community, who is represented in the featured Digital Artwork.
              </li>
              <li>
                <strong>Digital Artwork:</strong> The artwork created for usage
                by Collectr, illustrated by an artist and representing a
                community and its members.
              </li>
              <li>
                <strong>NFT:</strong> The digital asset created on the
                blockchain that users purchase.
              </li>
              <li>
                <strong>Smart Contract:</strong> A censorship-resistant and
                decentralized contract on the Base blockchain that accepts mint
                funds and provides the NFTs.
              </li>
            </ul>
          </p>

          <DropHead>3. User Responsibilities</DropHead>

          <p>
            Users are responsible for any activities that occur under their
            interactions with the smart contract. They must ensure that their
            use of the platform complies with all applicable laws and
            regulations.
          </p>
          <p>
            <ul>
              <li>
                <strong>Compliance:</strong> Users must comply with all relevant
                laws and regulations, including those related to financial
                transactions and digital assets.
              </li>

              <li>
                <strong>Security:</strong> Users are responsible for the
                security of their digital wallets and private keys. Collectr
                cannot recover lost or stolen private keys.
              </li>
            </ul>
          </p>

          <DropHead>4. Payment and Transactions</DropHead>

          <p>
            Payments on Collectr are decentralized. When users send ETH to the
            smart contract to mint an edition, the payment is automatically
            split among the designated parties. These parties may include but
            are not limited to, Collectr, the featured community(ies), community
            member(s), and the artist(s).
          </p>
          <p>
            <ul>
              <li>
                <strong>Decentralized Payments:</strong> Users send ETH to the
                smart contract to mint an edition. The payment is automatically
                divided among the designated parties.
              </li>
              <li>
                <strong>Smart Contract Execution:</strong> The smart contract
                manages all financial transactions on the blockchain.
              </li>
              <li>
                <strong>No Custody of Funds:</strong> Collectr never holds user
                funds; the smart contract directly manages all transactions.
              </li>
            </ul>
          </p>

          <DropHead>5. Use of Platform</DropHead>

          <p>
            Users can mint NFTs without the need for an account. Collectr
            facilitates transactions through a smart contract between users and
            the featured artists and communities but does not take custody or
            possession of any funds, except for the agreed operating share. The
            smart contract manages the distribution of payments directly.
          </p>
          <p>
            <ul>
              <li>
                <strong>Accessibility:</strong> The platform is accessible to
                anyone with a compatible digital wallet, such as MetaMask. No
                registration or personal data is required.
              </li>
              <li>
                <strong>Minting Process:</strong> Users interact with the
                platform to mint NFTs. The process involves connecting a digital
                wallet, sending the specified amount of ETH, and receiving the
                minted NFT.
              </li>
            </ul>
          </p>

          <DropHead>6. Prohibited Activities</DropHead>

          <p>
            Users must not engage in unlawful activities on the platform. Any
            illegal activity will be reported to the appropriate authorities.
          </p>
          <p>
            <ul>
              <li>
                <strong>Examples of Prohibited Activities:</strong> This
                includes, but is not limited to, money laundering, financing of
                terrorism, and any other activities that violate applicable laws
                and regulations.
              </li>
              <li>
                <strong>Monitoring and Enforcement:</strong> Collectr does not
                actively monitor user activities. However, any detected illegal
                activities will be investigated and reported to the relevant
                authorities.
              </li>
            </ul>
          </p>

          <DropHead>7. Intellectual Property Rights</DropHead>

          <p>
            Collectr obtains the right to use and commercially distribute the
            featured art from the artists and community members. These rights
            are used for the marketing and promotion of the platform and the
            featured artists and communities.
          </p>
          <p>
            <ul>
              <li>
                <strong>License to Collectr:</strong> By participating, artists
                and communities grant Collectr a non-exclusive, royalty-free,
                worldwide license to use, reproduce, distribute, and display the
                Digital Artwork for promotional and commercial purposes.
              </li>
              <li>
                <strong>Artist and Community Rights:</strong> The artist and
                community are granted a non-exclusive license to use,
                distribute, display, and remix the artwork for promotional
                purposes.
              </li>
            </ul>
          </p>

          <DropHead>8. Limitation of Liability</DropHead>

          <p>
            Collectr is not responsible for any losses or damages resulting from
            the use of the platform. The smart contract handles all
            transactions, and users understand the inherent risks involved in
            participating in blockchain technology and smart contracts.
          </p>
          <p>
            <ul>
              <li>
                <strong>Risk Acknowledgment:</strong> Users acknowledge they are
                responsible for the actions they perform with their wallet,
                including any potential risks such as loss of funds or Digital
                Artwork associated with using the platform.
              </li>
              <li>
                <strong>No Guarantees:</strong> Collectr does not guarantee the
                performance of our website interface, smart contracts, the
                blockchain network, or any third-party services. Collectr does
                not guarantee the security of the smart contract.
              </li>
            </ul>
          </p>

          <DropHead>9. Dispute Resolution</DropHead>

          <p>
            All transactions are final and governed by the smart contract. There
            is no arbitration process, as users understand and accept the
            decentralized nature of the platform.
          </p>
          <p>
            <ul>
              <li>
                <strong>Finality of Transactions:</strong> All transactions
                conducted through the smart contract are final and irreversible.
              </li>
              <li>
                <strong>Waiver of Legal Action:</strong> By using Collectr and
                minting our NFTs, users agree to waive their right to pursue any
                form of legal action, including but not limited to arbitration
                or court proceedings, against Collectr, its affiliates, and
                associated parties. Users acknowledge that all transactions are
                final and governed by the smart contract.
              </li>
            </ul>
          </p>

          <DropHead>10. Changes to the Terms</DropHead>

          <p>
            Collectr reserves the right to modify these terms at any time, with
            or without notice to users.
          </p>
          <p>
            <ul>
              <li>
                <strong>Notification of Changes:</strong> Updates to the Terms
                of Service will be posted on the platform. Users are encouraged
                to review the terms periodically.
              </li>
              <li>
                <strong>Effective Date:</strong> Changes to the terms will
                become effective 14 days after they are posted, except for
                changes addressing new functions of the platform, which will be
                effective immediately.
              </li>
            </ul>
          </p>

          <DropHead>11. Fees and Royalties</DropHead>

          <p>
            Collectr receives a portion of the payment submitted for the NFTs
            sold on our platform that cover costs that may include but are not
            limited to development, operation, and management. Royalties are not
            enforced as part of the smart contract for Collectr.
          </p>
          <p>
            <ul>
              <li>
                <strong>Minting Price:</strong> A payment is made to the smart
                contract to mint the NFT.
              </li>
              <li>
                <strong>Gas Fees:</strong> Users are responsible for paying the
                Gas Fees required to process transactions on the blockchain.
              </li>
            </ul>
          </p>

          <DropHead>12. Privacy</DropHead>

          <p>
            Collectr respects user privacy and users can opt-in to provide email
            addresses. The platform uses Google Analytics for traffic analysis.
            We are committed to protecting user privacy and ensuring that any
            data collected is used responsibly and in compliance with applicable
            laws.
          </p>

          <DropHead>13. Contact Information</DropHead>

          <p>
            For any questions or concerns, please contact us at{" "}
            <a href="mailto:collectr@uwulabs.com">collectr@uwulabs.com</a>.
          </p>
          <Spacer />
        </SingleContainerContent>
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
