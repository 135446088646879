export const Farcaster = () => (
  <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M4.11859 0.043457H19.5979V21.9565H17.3257V11.9189H17.3034C17.0523 9.14476 14.7103 6.97081 11.8582 6.97081C9.00619 6.97081 6.66419 9.14476 6.41307 11.9189H6.39078V21.9565H4.11859V0.043457Z"/>
    <path fillRule="evenodd" d="M0.000244141 3.1537L0.923321 6.26393H1.70439V18.8463C1.31223 18.8463 0.994327 19.1627 0.994327 19.5531V20.4014H0.852315C0.46016 20.4014 0.142256 20.7179 0.142256 21.1083V21.9565H8.09492V21.1083C8.09492 20.7179 7.77702 20.4014 7.38486 20.4014H7.24285V19.5531C7.24285 19.1627 6.92494 18.8463 6.53279 18.8463H5.68072V3.1537H0.000244141Z"/>
    <path fillRule="evenodd" d="M17.4677 18.8463C17.0755 18.8463 16.7576 19.1627 16.7576 19.5531V20.4014H16.6156C16.2235 20.4014 15.9056 20.7179 15.9056 21.1083V21.9565H23.8582V21.1083C23.8582 20.7179 23.5403 20.4014 23.1482 20.4014H23.0062V19.5531C23.0062 19.1627 22.6883 18.8463 22.2961 18.8463V6.26393H23.0772L24.0002 3.1537H18.3198V18.8463H17.4677Z" />
  </svg>
);

export const Foundation = () => (
  <svg 
    width="96"
    height="96"
    fill="none" 
    viewBox="0 0 96 48" 
    preserveAspectRatio="xMaxYMax meet" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="m64.8935 16.456c0 9.0884-7.3676 16.456-16.456 16.456s-16.456-7.3676-16.456-16.456c0-9.08839 7.3676-16.456 16.456-16.456s16.456 7.36761 16.456 16.456zm-47.9911-14.88859c.3016-.52241 1.0556-.52241 1.3572 0l16.7962 29.09189c.3017.5224-.0754 1.1754-.6786 1.1754h-33.592412c-.603231 0-.980249-.653-.678634-1.1754zm51.7119-.588048c-.8655 0-1.5672.701678-1.5672 1.567238v27.8185c0 .8656.7017 1.5673 1.5672 1.5673h27.8185c.8656 0 1.5673-.7017 1.5673-1.5673v-27.8185c0-.86556-.7017-1.567238-1.5673-1.567238z"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const Looksrare = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 55.7837C39.7639 55.7837 33.0811 49.1076 33.0811 40.8648C33.0811 32.6219 39.7639 25.9458 48 25.9458C56.2361 25.9458 62.9189 32.6219 62.9189 40.8648C62.9189 49.1076 56.2361 55.7837 48 55.7837ZM41.5135 40.8648C41.5135 44.4486 44.4191 47.3512 48 47.3512C51.5809 47.3512 54.4865 44.4486 54.4865 40.8648C54.4865 37.2809 51.5809 34.3783 48 34.3783C44.4191 34.3783 41.5135 37.2809 41.5135 40.8648Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 40.8845L28.5405 12.3242H67.4595L96 40.8845L48 88.8648L0 40.8845ZM70.0541 30.4862C57.9274 18.3063 38.0726 18.3064 25.9459 30.4863L15.5676 40.8648L25.9459 51.2433C38.0726 63.4232 57.9274 63.4231 70.0541 51.2432L80.4324 40.8648L70.0541 30.4862Z"
    />
  </svg>
);

export const Sealed = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke-width="0"
      d="m111.56,118.51c-.17.11-.36.25-.43.42-1.12,2.67-3.39,5.66-5.44,7.45-1.19,1.04-2.44,2.01-3.73,2.91-.91.64-1.9,1.2-2.93,1.63-1.16.49-2.34.2-3.37-.46-.86-.55-1.74-2.56-2.03-3.61-.14-.49-.58-.52-1.01-.41-.79.2-2.75.75-3.55.85-2.96.37-4.67.61-7.71.74-3.14.14-5.87.1-8.91-.62-.12-.03-.58-.19-.69-.21-1.02-.22-1.33-.19-1.98.36-1.31,1.11-2.67,2.2-4.11,3.13-1.49.96-3.02,1.9-4.67,2.54-1.83.71-5.26,1.3-7.05,1.45-3.41.28-6.53-.75-7.85-1.46-.6-.33-1.15-1.31-1.36-2.06-.42-1.5.25-3.07.69-4.5.42-1.36.93-2.58,1.41-3.92l1.1-2.77c.03-.21-.05-.29-.13-.33-2.01-1.01-4.01-1.75-5.76-2.94-2.78-1.89-7.55-5.97-10.21-8.74-.89-.93-1.93-2.1-2.74-3.1-.64-.8-1.9-2.77-1.9-2.77-1.42-2.17-2.52-4.23-3.43-6.98-.63-1.9-1.07-3.87-1.43-5.83-.28-1.53-.36-3.1-.41-4.66-.08-2.54-.2-5.09-.05-7.63.15-2.59.49-5.17.96-7.72.4-2.16,1.27-6.46,1.8-8.6.63-2.56,2.07-7.63,2.85-10.14.36-1.16.88-2.27,1.18-3.44.13-.52-.02-1.16-.21-1.69-.53-1.48-1.1-2.95-1.74-4.39-.81-1.8-1.64-3.6-2.57-5.34-1.1-2.08-2.34-4.09-3.47-6.16-.87-1.58-1.77-3.16-2.45-4.82-.46-1.12-.69-2.39-.75-3.61-.07-1.28.72-2.44,1.89-2.74.91-.23,2.82-.18,3.76-.08,1.77.18,3.84.89,5.57,1.73,2.53,1.44,4.18,2.38,6.69,4.87.59.58,1.1,1.07,1.67,1.67.17.18.58.63.62.59l1.84-4.69c.21-.51.4-1.04.64-1.53,1.02-2.09,2.01-4.2,3.1-6.25.57-1.07,1.21-2.12,2.01-3.03.92-1.04,2.24-1.68,3.64-.61.63.49,1.11,1.06,1.43,2.03.81,2.63,1.31,5.15,1.32,8.1,0,3.58-.8,6.9-1.65,10.27-.54,2.17-1.27,4.29-1.69,6.48-.17.86,0,1.93.46,2.74,2.47,4.43,9.1,12.28,12.76,15.79,2.73,2.62,5.91,4.57,9.26,6.22,1.65.81,3.46,1.3,5.21,1.89.26.09.62.03.87-.09,0,0,6.42-2.94,8.7-3.45,3.9-.87,6.99-1.1,10.6-.65,1.94.24,3.48.6,5.36,1.1,5.34,1.44,10.28,3.79,14.65,7.19,1.72,1.34,3.34,2.79,4.69,4.57,1.42,1.87,2.73,3.78,3.87,5.83.78,1.39,1.76,2.67,2.61,4.03,1.17,1.85,1.98,3.85,2.36,6,.75,4.22.61,8.49-1.41,12.26-3.79,7.1-11.18,13.33-16.75,17.15Z"
    />
    <path
      stroke-width="0"
      d="m105.75,93.82c-1.41,1.48-4.27,3.14-5.87,3.59-.82.23-1.68.48-2.57.42-1.15-.08-2.07-1.21-2.03-2.32.02-.64.52-.79.85-.46.24.23.38.64.57.91.72,1,1.35.85,2.52.49,1.27-.39,2.42-.87,3.63-1.57.76-.44,1.45-1.1,2.03-1.77.5-.58.95-.76,1.73-.58,1.83.42,3.75.53,5.06-.38.37-.32.97-.75,1.27-1.35.35-.69.48-1.58,1.16-1.3.59.24.53,1.03.12,1.99-.84,1.43-2.43,2.11-3.5,2.31s-2.97.02-4.24-.16c-.22-.03-.57.02-.73.19Z"
    />
    <path
      stroke-width="0"
      d="m102.72,89.74c-.52.1-.93-.7-1.01-1.17-.09-.51.28-.85.58-.98,1.07-.46,2.94-.91,4.08-1.07.59-.08.85.25,1.16.81.27.5.17.98-.37,1.16-1.45.48-3.28,1.03-4.44,1.25Z"
    />
    <ellipse
      fill="#fff"
      stroke-width="0"
      cx="81.27"
      cy="90.69"
      rx="7.52"
      ry="7.66"
    />
    <ellipse
      fill="#fff"
      stroke-width="0"
      cx="115.53"
      cy="80.95"
      rx="4.83"
      ry="6.44"
      transform="translate(-22.86 53.58) rotate(-23.83)"
    />
    <path
      fill="#fff"
      stroke-width="0"
      d="m47.56,119.84c-30.25-13.76-29.72-40.14-20.98-67.18.47-1.44.96-2.89,1.47-4.34.13-.36.28-1.38.3-1.76.2-5.89-16.19-24.22-9.08-28.55,1.19-.84,4.47-.36,5.7.12,4.28,1.67,8.12,4.31,11.54,7.81,1.05,1.07,1.02.56,1.35.1.51-.71,1.61-3.7,2.39-5.57,3.03-7.23,6.2-12.1,8.9-10.22,1.4.98,2.59,4.21,2.88,10.38.32,6.87-2.8,12.81-3.32,19.21,4.81,8.37,21.77,29.41,32.38,22.88,16.07-6.79,37.59.88,44.83,16.44,5.67,6.55,7.23,14.92,3.6,22.54-3.83,7.27-10.17,12.85-16.93,17.34-3.08,4.71-7.14,10.83-12.56,12.37-4.02,1.15-5.56-1.87-6.24-3.89-.14-.5-.44-.52-.88-.41-6.84,1.43-13.7,2.5-20.57.72-1.25-.32-1.35-.2-2,.36-2.66,2.35-5.54,4.54-8.88,5.73-8.3,2.94-15.31,2.53-16.84-1.63-.93-2.54,0-6.85,2.94-12.45Zm-8.29-89.95c-.32,1.03-.99.76-1.45.29-3.7-3.9-8.11-7.78-13.27-9.67-1.47-.54-5.92-1.82-4.67,1.59,1.48,4.07,2.47,7.01,4.68,10.3s3.86,6.5,5.19,9.73c.16.38.26.75.38,1.13,1.04,3.35.22,5.47-.75,8.71-4.06,13.55-7.34,28.82-2.41,42.58,3.62,9.87,11.9,19.43,22.14,22.64,1.7-1.5,2.12-1.76,3.99-2.7-2.55,4.3-5.45,8.86-6.22,13.02-.3,1.61-.1,3.79,1.01,4.85,1.34,1.27,4.32,1.05,6.61.73,7.15-.99,15.48-7.58,19.47-11.19-.5,1.71-1,2.77-1.7,4,16.01,2.51,40.96-5.01,51.39-19.15,3.55-4.81,6.72-9.6,5.71-15.91-.58-3.63-2.32-6.94-4.91-10.31-5.42-11.13-18.2-17.76-30.91-18.12-10.04-.29-17.18,5.26-24.93,11.48.85-3.24,3.28-5.04,5.12-6.47,1.4-1.08-1.64-1.71-2.35-2.1-4.53-1.5-8.13-4.31-11.71-7.27-5.68-5.34-9.4-10.37-13.04-17.25-1.09-2.23-.19-5.68.71-8.27.75-2.13,1.75-5.25,2.06-8.39.49-4.94-.09-9.89-1.33-10.95-1.08-.92-2.87,1.07-6.03,8.56-1.22,2.89-1.85,4.5-2.78,8.14Zm68.82,92.31c.1-.14-.08-.34-.38-.2-7.1,3.36-8.17,3.6-12.31,4.55,2.17,5.51,6.85,3.07,12.69-4.35Z"
    />
    <path
      fill="#fff"
      stroke-width="0"
      d="m140.59,87.95c-1.56-.4-3.38-.22-4.95.03-1.68.26-3.21.74-4.71,1.54-.61.33-.07,1.18.53.91,1.39-.61,2.89-1.12,4.38-1.41.77-.15,1.54-.24,2.32-.28s1.55.02,2.33-.03c.42-.03.54-.64.1-.76h0Z"
    />
    <path
      fill="#fff"
      stroke-width="0"
      d="m140.64,96.22c-1.23-1.03-2.93-1.66-4.45-2.12-.78-.23-1.59-.42-2.39-.55-.85-.14-1.66-.14-2.52-.1-.68.03-.68,1.04,0,1.06,1.54.05,3.14.23,4.63.62.75.2,1.48.44,2.19.73s1.43.7,2.17,1c.35.14.66-.4.37-.64h0Z"
    />
    <path
      stroke-width="0"
      d="m63.53,103.21c.76-.2,1.5-.5,2.26-.7s1.55-.36,2.34-.45c1.52-.18,3.13-.14,4.65.02.59.06.73-.93.14-1.04-.83-.15-1.62-.28-2.46-.26s-1.66.1-2.47.22c-1.58.24-3.38.63-4.75,1.49-.37.23-.13.81.3.7h0Z"
    />
    <path
      stroke-width="0"
      d="m66.33,110.85c.6-.5,1.13-1.08,1.72-1.59s1.22-.96,1.88-1.38,1.3-.76,1.98-1.07,1.44-.65,2.17-.91c.63-.23.38-1.2-.28-1.02-.8.22-1.56.45-2.31.83s-1.47.81-2.16,1.28-1.32.98-1.93,1.53-1.24,1.16-1.68,1.86c-.25.39.29.73.6.47h0Z"
    />
    <path
      stroke-width="0"
      d="m104.47,88.89s1.35,2.69.47,4.53c-.11.23,2.25-.57,2.07-.33,0,0,.2-2.8-1.13-4.46-1.47-1.93-1.4.27-1.4.27Z"
    />
  </svg>
);

export const TwitterX = () => (
  <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
  </svg>
);
