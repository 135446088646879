import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";

import defaultTheme from "@theme/theme";
import App from "./App";
import GlobalStyle from "./GlobalStyles";

import "./firebase";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
