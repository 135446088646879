import styled from "styled-components";
import {
  DeviceShowType,
  Spacer,
  Container,
  ContainerContent,
  VerticalStack,
} from "@components/Layout";
import { Title, DropHead as StyledDropHead } from "@components/Text";
import { Footer } from "@components/Footer";

const SingleContainerContent = styled(ContainerContent)`
  margin-top: 40px;
  max-width: 800px;
`;

const DropHead = styled(StyledDropHead)`
  margin-top: 40px;
  margin-bottom: 8px;
`;

const Privacy = () => {
  return (
    <>
      <Container>
        <SingleContainerContent>
          <Spacer $deviceShowType={DeviceShowType.MOBILEONLY} />
          <VerticalStack>
            <Title>Privacy Policy</Title>
            <p>Last updated: October 26, 2024</p>
          </VerticalStack>
          <Spacer />

          <p>
            This Privacy Policy describes how Collectr (the "Site", "we", "us",
            or "our") collects, uses, and discloses your personal information
            when you visit, use our services, or make a purchase from
            https://collectr.live (the "Site") or otherwise communicate
            with us (collectively, the "Services"). For purposes of this Privacy
            Policy, "you" and "your" means you as the user of the Services,
            whether you are a customer, website visitor, or another individual
            whose information we have collected under this Privacy Policy.
          </p>

          <p>
            Please read this Privacy Policy carefully. By using and accessing
            any of the Services, you agree to the collection, use, and
            disclosure of your information as described in this Privacy Policy.
            If you do not agree to this Privacy Policy, please do not use or
            access any of the Services.
          </p>

          <DropHead>Changes to This Privacy Policy</DropHead>

          <p>
            We may update this Privacy Policy from time to time, including to
            reflect changes to our practices or for other operational, legal, or
            regulatory reasons. We will post the revised Privacy Policy on the
            Site, update the "Last updated" date, and take any other steps
            required by applicable law. Additionally, we will notify you of any
            significant changes by email.
          </p>

          <DropHead>What Personal Information We Collect</DropHead>

          <p>
            We collect your email address when you opt-in on our website to
            receive updates about new digital art editions, NFT drops, and
            important updates to our Terms of Service and Privacy Policy.
          </p>

          <DropHead>How We Use Your Personal Information</DropHead>

          <p>
            Email Marketing: We use your email address to send you newsletters
            and announcements about new NFT launches each month. We use Beehiiv
            for our email marketing services. You can review their privacy
            policy here.
          </p>

          <DropHead>How We Disclose Personal Information</DropHead>

          <p>
            We do not share your personal information with any third parties.
            User authentication on our platform is handled through blockchain
            addresses using MetaMask or other browser extensions.
          </p>

          <DropHead>Cookies and Tracking Technologies</DropHead>

          <p>
            We use Google Analytics to collect information about your
            interaction with the Site. This includes your IP address, browser
            type, operating system, referring URLs, and other usage data. This
            helps us understand how visitors use our Site and improve our
            services.
          </p>

          <DropHead>Third-Party Websites and Links</DropHead>

          <p>
            Our Site may provide links to websites or other online platforms
            operated by third parties, such as social media sites (e.g. Discord
            and X). If you follow links to sites not affiliated or controlled by
            us, you should review their privacy and security policies. We do not
            guarantee and are not responsible for the privacy or security of
            such sites.
          </p>

          <DropHead>Children's Data</DropHead>

          <p>
            The Services are not intended for use by children under the age of
            13. We do not knowingly collect any personal information from
            children. If you are the parent or guardian of a child who has
            provided us with their personal information, you may contact us to
            request that it be deleted.
          </p>

          <DropHead>Security of Your Information</DropHead>

          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, use, or disclosure. Although
            we do not store data ourselves, our service providers (such as
            Beehiiv) use advanced security practices to protect your
            information.
          </p>

          <DropHead>International Users</DropHead>

          <p>
            Please note that we may transfer, store, and process your personal
            information outside the country you live in, including the United
            States. Your personal information is also processed by staff and
            third-party service providers and partners in these countries.
          </p>

          <DropHead>Your Rights and Choices</DropHead>

          <p>
            You have the right to unsubscribe from our email communications at
            any time by using the unsubscribe option in our emails. Since we do
            not store personal data beyond your email address, there are no
            additional data-related rights or choices applicable.
          </p>

          <DropHead>Contact Us</DropHead>

          <p>
            If you have any questions about our privacy practices or this
            Privacy Policy, or if you would like to exercise any of the rights
            available to you, please email us at collectr@uwulabs.com or contact us
            at:
          </p>

          <p>
            <br />
            Collectr
            <br />
            30 North Gould Ste R<br />
            Sheridan, WY 82801
            <br />
            United States
          </p>
          <Spacer />
        </SingleContainerContent>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
