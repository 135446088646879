import styled from "styled-components";

import { SVGButton } from "@components/Buttons";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: relative;
  max-height: 70%;
  max-width: 90vw;
  color: ${({ theme }) => theme.colors.fgColor};
  background-color: ${({ theme }) => theme.colors.secondaryBgColor};
  padding: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryActive};
    }
  }
`;

export const ModalImage = styled.div<{ imgUrl?: string }>`
  height: 200px;
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgba(16, 40, 66, 0) 62.2%,
      #102842 100%
    ),
    url(${({ imgUrl }) => (imgUrl ? imgUrl : "")});
  background-position: 50% 25%;
  background-size: cover;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

export const ModalTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.fgColor};
  text-align: left;
  padding: 12px;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.fontSizes.large};
    padding: 16px 24px;
  }
`;

export const ModalPillTitle = styled(ModalTitle)`
  background: ${({ theme }) => theme.colors.primary};
  display: inline-block;
  border-radius: 32px;
  margin: 8px;
  padding: 8px 12px;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.fontSizes.normal};
    margin: 8px;
    padding: 8px 12px;
  }
`;

interface ContentWrapperProps {
  overflowY?: boolean;
  padding: string;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  flex: 1;
  overflow-y: ${({ overflowY }) => (overflowY ? "auto" : "none")};
  padding:  ${({ padding }) => (!!padding ? padding : "0 12px 18px 12px")};
  @media ${({ theme }) => theme.breakpoints.md} {
    padding:  ${({ padding }) => (!!padding ? padding : "0 24px 24px 24px")};
  }
`;

export const CloseButton = styled(SVGButton)`
  position: absolute;
  top: 2px;
  right: -8px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.grey60};
    height: 40px;
    width: 40px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    position: absolute;
    top: 10px;
    right: 0px;

    svg {
      fill: ${({ theme }) => theme.colors.grey60};
      height: 36px;
      width: 36px;
    }
  }
`;

export const CloseButtonWithBg = styled(CloseButton)`
  background-color: rgba(76, 76, 76, 0.7);
  width: 36px;
  margin-right: 8px;

  svg {
    margin: auto;
  }
`;
