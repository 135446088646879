import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { wagmiConfig } from "./config";

import usePageTracking from "@hooks/usePageTracking";

import { EditionsProvider } from "@context/EditionsProvider";

import NavBar from "@components/NavBar";

import { Edition } from "@pages/Edition";
import { Gallery } from "@pages/Gallery";
import { Privacy } from "@pages/Privacy";
import { Terms } from "@pages/Terms";
import { NotFound } from "@pages/NotFound";
import { RelayClient } from "@reservoir0x/relay-sdk";

const Home = lazy(() => import("@pages/Home"));
const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Suspense>
            <BrowserRouter>
              <EditionsProvider>
                <ToastContainer
                  transition={Slide}
                  limit={1}
                  closeOnClick={true}
                  pauseOnHover={false}
                  hideProgressBar={true}
                />
                <NavBar />
                <PageTracker />
                <Routes>
                  <Route path="/" Component={Home} />
                  <Route path="/:year/:month" Component={Edition} />
                  <Route path="/gallery" Component={Gallery} />
                  <Route path="/privacy" Component={Privacy} />
                  <Route path="/terms" Component={Terms} />
                  <Route path="*" Component={NotFound} />
                </Routes>
              </EditionsProvider>
            </BrowserRouter>
          </Suspense>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

const PageTracker = () => {
  usePageTracking();
  return null;
};

export default App;
