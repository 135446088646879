import { useState, useEffect } from "react";
import styled from "styled-components";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  OnChangeValue,
} from "react-select";
import {
  base,
  mainnet,
  arbitrum,
  optimism,
  zora,
  blast,
  baseSepolia,
  sepolia,
  arbitrumSepolia,
  optimismSepolia,
  zoraSepolia,
  scroll,
  zksync,
  zksyncSepoliaTestnet,
  scrollSepolia,
} from "viem/chains";
import { useAccount, useSwitchChain, useBalance } from "wagmi";
import { Info } from "@styled-icons/octicons";
import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { Callout } from "@components/Text";
import { ModalButton } from "@components/Buttons";
import { IS_MAINNET } from "@utils/constants";
import { ETH, Base, OP, Arbitrum, Zora, Blast, ZkSync, Scroll } from "@assets/NetworkLogos";
import { ModalContainerContent, PoweredByText } from "./styled";
import { parseEther } from "viem";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

interface PaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  instanceId: number;
  handlePayment: (selectedChain: number, performTX: bool) => void;
  url: string;
}

const StyledSelect = styled(Select) <any>`
  width: 100%;
  margin-bottom: 16px;
`;

const StyledSelectHStack = styled(HorizontalStack)`
  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
  img {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CrossmintContainer = styled.div`
  width: 100%;
  justify-content: flex-start;

  .crossmint-pay-button {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lessRoyalBlue};
  }

  .crossmintParagraph-0-2-3 {
    visibility: hidden;
  }
  
  .crossmint-pay-button:hover, .crossmintButton-0-2-1:hover:enabled {
    cursor: pointer;
    opacity: 0.6;
  }

  .crossmintParagraph-0-2-3:before {
    content: "Credit Card";
    position: block;
    visibility: visible;
    font-size: 18px;
    font-weight: 500;
  }

  .crossmintImg-0-2-2, .crossmintImg-1-2-2  {
    margin-right: 0.5rem;
    content:url("/images/credit-card.svg");
  }
`;


const MintWithETHContainer = styled.div`
  width: 100%;
  justify-content: flex-start;

  div {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lessRoyalBlue};
  }

  button {
    display: flex;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 15px;
    transition: opacity 0.25s ease-in-out 0s;
    align-items: center;
    border-radius: 0.5rem;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    background-color: #04458d;
    margin-bottom: 16px;
  }

  span {
    content: "Credit Card";
    position: block;
    visibility: visible;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }

  img {
    margin-top: auto;
    margin-left: 0.4rem;
    margin-bottom: auto;
    margin-right: 0.25rem;
    content:url("/images/eth-logo.svg");
  }

  button:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const NetworkName = styled.strong<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? "#bbb" : "#333333")};
`;

const Balance = styled.div<{ disabled?: boolean }>`
  font-size: 0.75em;
  color: ${(props) => (props.disabled ? "#bbb" : "#333333")};
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    padding: "0px 8px",
    boxSizing: "border-box",
  }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    padding: "8px",
    margin: "2px 0px",
    boxSizing: "border-box",
  }),
};

const minimum = IS_MAINNET ? parseEther("0.01") : parseEther("0.0000001");

const options = IS_MAINNET
  ? [base, mainnet, arbitrum, optimism, zksync, blast, scroll, zora]
  : [baseSepolia, sepolia, arbitrumSepolia, optimismSepolia, zksyncSepoliaTestnet, scrollSepolia, zoraSepolia];

const chainLogoMap: { [key: number]: React.ComponentType<any> } = {
  [mainnet.id]: ETH,
  [base.id]: Base,
  [arbitrum.id]: Arbitrum,
  [optimism.id]: OP,
  [zksync.id]: ZkSync,
  [blast.id]: Blast,
  [scroll.id]: Scroll,
  [zora.id]: Zora,
  [sepolia.id]: ETH,
  [baseSepolia.id]: Base,
  [arbitrumSepolia.id]: Arbitrum,
  [optimismSepolia.id]: OP,
  [zksyncSepoliaTestnet.id]: ZkSync,
  [scrollSepolia.id]: Scroll,
  [zoraSepolia.id]: Zora,
};

const Option = (props: OptionProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  const isDisabled = balanceData?.value < minimum;

  return (
    <components.Option {...props} isDisabled={isDisabled}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <NetworkName disabled={isDisabled}>{data.name}</NetworkName>
          <Balance disabled={isDisabled}>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  return (
    <components.SingleValue {...props}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <strong>{data.name}</strong>
          <Balance>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.SingleValue>
  );
};

const PaymentMethodModal = ({
  isOpen,
  onClose,
  instanceId,
  handlePayment,
  url,
}: PaymentMethodModalProps) => {
  const { address, chain } = useAccount();
  const { chains, switchChain } = useSwitchChain();
  const [ ethCheckout, setEthCheckout ] = useState(false);
  let defaultChain = chain || base;
  const [selectedChain, setSelectedChain] = useState(defaultChain);
  const [isConnectedToSelectedChain, setIsConnectedToSelectedChain] =
    useState(false);

  const { data: balanceData } = useBalance({
    address,
    chainId: selectedChain.id,
  });
  const [sufficientBalance, setSufficientBalance] = useState(
    balanceData?.value > minimum
  );

  useEffect(() => {
    if (chain && selectedChain) {
      if (balanceData?.value < minimum) {
        setSufficientBalance(false);
      } else {
        setSufficientBalance(true);
      }
      setIsConnectedToSelectedChain(chain.id === selectedChain.id);
    }
  }, [chain, selectedChain]);

  useEffect(() => {
    if (chain) {
      const connectedChain = options.find((option) => option.id === chain.id);
      if (connectedChain) {
        setSelectedChain(connectedChain);
      }
    }
  }, [chain]);

  const onChange = (value: OnChangeValue<OptionType, false>) => {
    setSelectedChain(value);
  };

  const handleButtonClick = () => {
    if (isConnectedToSelectedChain) {
      handlePayment(selectedChain.id, true);
    } else {
      switchChain?.({ chainId: selectedChain.id });
    }
  };

  const onModalClose = () => {
    setEthCheckout(false);
    onClose();
  }

  const handleEthCheckoutClick = () => {
    setEthCheckout(true);
  };

  const handleCreditCardClick = () => {
    handlePayment(defaultChain.id, false);
  };

  console.log(url);
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      title={ethCheckout ? "Choose payment method" : "Choose checkout"}
      overflowY={false}
    >
      <ContainerContent>
        <HorizontalStack>
          { ethCheckout ? (
            <VerticalStack>
              <ModalContainerContent>
                <Callout>
                  <Info size="16" />
                  &nbsp; Pay from any network, receive on Base
                </Callout>
              </ModalContainerContent>

              <StyledSelect
                components={{ Option, SingleValue }}
                options={options}
                getOptionLabel={(option: OptionType) => option.name}
                getOptionValue={(option: OptionType) => option.id}
                isSearchable={false}
                onChange={onChange}
                defaultValue={selectedChain}
                styles={customStyles}
              />

              <ModalButton
                isDisabled={!sufficientBalance}
                onClick={handleButtonClick}
              >
                {sufficientBalance
                  ? isConnectedToSelectedChain
                    ? "collect"
                    : "switch network"
                  : "not enough ETH"}
              </ModalButton>
            </VerticalStack>
            ) : null
          }
          {ethCheckout ? null : (
          <VerticalStack>
            <MintWithETHContainer>
              <button onClick={handleEthCheckoutClick}>
                <img />
                <span>Mint with ETH</span>
              </button>
            </MintWithETHContainer>
            {IS_MAINNET ? (
              <CrossmintContainer>
                <CrossmintPayButton
                  collectionId="f99df614-15ec-414c-963b-4cde0291fb4d"
                  projectId="c3df53c1-9d4f-4220-a18f-83ea91a2f7bf"
                  mintConfig={{ totalPrice: "0.01", instanceId: `${instanceId}` }}
                  mintTo={address}
                  onClick={handleCreditCardClick}
                  checkoutProps={{experimental: true, display: "same-tab", paymentMethods: ["fiat"] }}
                  className="crossmint-pay-button"
                  successCallbackURL={url}
                  failureCallbackURL={url}
                />
              </CrossmintContainer>
            ) : (
              <CrossmintContainer>
                <CrossmintPayButton
                  collectionId="e035debd-cc1c-414b-809c-0749edb56d27"
                  projectId="2ee7c748-7fa4-4851-8047-071aae375ba7"
                  mintConfig={{ totalPrice: "0.0000001", instanceId: `${0}` }}
                  mintTo={address}
                  environment="staging"
                  onClick={handleCreditCardClick}
                  checkoutProps={{experimental: true, display: "same-tab", paymentMethods: ["fiat"] }}
                  className="crossmint-pay-button"
                  successCallbackURL={url}
                  failureCallbackURL={url}
                />
              </CrossmintContainer>)
            }
          </VerticalStack>
          )}
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default PaymentMethodModal;
