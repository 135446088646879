import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mundial Black';
    src: url('/fonts/Mundial Black.otf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Mundial Regular';
    src: url('/fonts/Mundial Regular.otf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Carbona Variable';
    src: url('/fonts/Carbona Variable.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Practical Regular';
    src: url('/fonts/Practical Regular.otf');
    font-display: swap;
  }

  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-family: 'Carbona Variable', sans-serif;
      font-weight: 400;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: #041426;
    color: #F5F5F5;
  }

  p {
    font-family: 'Mundial Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  strong {
    font-weight: 700;
  }

  .custom-toast {
    font-family: 'Carbona Variable', sans-serif;
    background-color: #00438F !important; 
    color: white !important;
  }

  .Toastify__close-button > svg {
    fill: white !important;
    transition: fill 0.3s ease-in-out;
  }

  .Toastify__close-button:hover > svg,
  .Toastify__close-button:focus > svg {
    fill: white !important;
  }

  .Toastify__close-button--light {
    opacity: 0.75;
  }
`;
export default GlobalStyle;
