import { Twitter, Opensea, Discord } from "@styled-icons/simple-icons";
import { Globe } from "@styled-icons/octicons";

export const SOCIAL_ICONS = {
  TWITTER: <Twitter />,
  WEBSITE: <Globe />,
  OPENSEA: <Opensea />,
  DISCORD: <Discord />,
};

export interface CardImageProps {
  imgurl: string;
}

export interface EditionCardProps {
  year: number;
  month: string;
  imgurl: string;
  title: string;
  creatorName: string;
  creatorFlag?: string;
  price: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  hasMinted?: boolean;
}

export interface InfoCardProps {
  imgurl?: string;
  title: React.ReactNode;
  children: React.ReactNode;
}

type SocialTypes = keyof typeof SOCIAL_ICONS;
type SocialIcons = (typeof SOCIAL_ICONS)[SocialTypes];

export interface Social {
  url: string;
  icon: SocialIcons;
}
