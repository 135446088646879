import { Info } from "@styled-icons/octicons";
import { Modal } from "@components/Modal";
import { Callout } from "@components/Text";
import { ProgressBar } from "@components/ProgressBar";

import { PoweredByText, SplitLine } from "./styled";

interface SplitModalProps {
  isOpen: boolean;
  onClose: () => void;
  splitData: {
    collector: number;
    artist: number;
    project: number;
    community: number;
    splitsPage: string;
  };
  artistName: string;
  communityName: string;
}

const SplitModal = ({
  isOpen,
  onClose,
  splitData,
  artistName,
  communityName,
}: SplitModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Split Info">
      <Callout>
        <Info size="16" />
        { !!splitData.splitsPage ? (
          <p>
            Proceeds distribute onchain via
            <PoweredByText
              href={splitData.splitsPage}
              target="_blank"
              rel="noreferrer"
            >
              {" Splits Protocol"}
            </PoweredByText>
          </p> 
        ) : (
          <p>
            all proceeds are distributed onchain at mint
          </p>
        )
      }
      </Callout>
      <SplitLine>
        <p>Collectr</p>
        <p>{`${splitData.collector}%`}</p>
      </SplitLine>
      <ProgressBar index={1}  progress={splitData.collector} />
      <SplitLine>
        <p>{`Artist: ${artistName}`}</p>
        <p>{`${splitData.artist}%`}</p>
      </SplitLine>
      <ProgressBar index={2}  progress={splitData.artist} />
      <SplitLine>
        <p>{`Featured Partner: ${communityName}`}</p>
        <p>{`${splitData.project}%`}</p>
      </SplitLine>
      <ProgressBar index={3}  progress={splitData.project} />
      {
        splitData.community > 0 ? (
        <div> 
        <SplitLine>
          <p>Featured Community Members</p>
          <p>{`${splitData.community}%`}</p>
        </SplitLine>
        <ProgressBar index={4} progress={splitData.community} />
        </div>
        ) : null
      }
    </Modal>
  );
};

export default SplitModal;
