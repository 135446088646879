import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { SubscribeForm } from "@components/SubscribeForm";

interface SubscribeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SubscribeModal = ({ isOpen, onClose }: SubscribeModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Stay Notified">
      <ContainerContent>
        <HorizontalStack>
          <VerticalStack>
            <p>
              New art every month. You can collect any time you'd like. We won't
              spam you!
            </p>
            <SubscribeForm />
          </VerticalStack>
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default SubscribeModal;
