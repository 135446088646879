import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AlignType, Container, VerticalStack } from "@components/Layout";
import { Headline, Title } from "@components/Text";
import { Button } from "@components/Buttons";

const NotFoundContainer = styled(Container)`
  background: linear-gradient(
    178deg,
    #0f2742 1.72%,
    #1b3046 78.98%,
    #314c6a 110.11%
  );
  align-content: center;
  height: 100vh;
`;

const NotFoundVStack = styled(VerticalStack)`
  position: relative;
  max-width: 100%;
  z-index: 2;
`;

const NotFoundHeadline = styled(Headline)`
  font-family: "Carbona Variable", sans-serif;
  font-style: normal;
`;

const NotFoundTitle = styled(Title)`
  font-family: "Carbona Variable", sans-serif;
  font-style: normal;
  max-width: 100%;
  text-align: center;
  white-space: wrap;
  margin-bottom: 64px;
`;

const Chungus404 = styled.div`
  position: absolute;
  right: 0;
  transform: translateY(-48px);
  color: #0d3159;
  font-family: "Carbona Variable", sans-serif;
  font-size: 160px;
  font-weight: 900;
  z-index: -1;

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 400px;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      <NotFoundContainer $fullHeight={true}>
        <NotFoundVStack align="start" $alignType={AlignType.CENTER}>
          <NotFoundHeadline>You've Hit a Blank Canvas.</NotFoundHeadline>
          <NotFoundTitle>
            Let’s go back and fill the page with some amazing art!
          </NotFoundTitle>
          <Button onClick={handleGoHome}>back to home</Button>
          <Chungus404>404</Chungus404>
        </NotFoundVStack>
      </NotFoundContainer>
    </>
  );
};

export default NotFound;
