import styled from "styled-components";

export const RoundedImage = styled.img`
  display: block;
  border-radius: ${({ theme }) => theme.radii.x4};
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.6));
  width: 100%;
  object-fit: contain;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: auto;
    max-width: 100%;
  }
`;

export const LogoTypeImage = styled.img`
  display: block;
  width: auto;
  height: 25px;
  object-fit: contain;

  &:hover {
    cursor: pointer;
  }
`;
