import styled from "styled-components";
import { ThreeBars } from "@styled-icons/octicons";

import { IconLink } from "@components/Links";
import { Button } from "@components/Buttons";
import NavItem from "./NavItem";
import Logo from "./Logo";

export const StyledNavItem = styled(NavItem)`
  white-space: nowrap;
  position: relative;
  padding: 0;
  padding-left 44px;
  text-transform: uppercase;
  font-weight: 700;

  &.highlight {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const HamburgerIcon = styled(ThreeBars)`
  color: ${({ theme }) => theme.colors.fgColor};
  height: 20px;
  width: 20px;

  @media ${({ theme }) => theme.breakpoints.md} {
    display: none;
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLogo = styled(Logo)`
  font-family: "Como Bold", sans-serif;
  font-weight: 900;
  justify-content: left;
  border-right: 0;
  padding: 0;
  flex: 1;
`;

export const SoonTag = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50px;
  position: absolute;
  background: transparent;
  font-size: 0.5em;
  font-weight: bold;
  top: 0;
  right: -${({ theme }) => theme.spacing.x3};
  padding: 1.5px 5px;
  color: ${({ theme }) => theme.colors.primary};
  margin: -3px 0;
  z-index: -1;
`;

export const BackButton = styled(IconLink)`
  color: ${({ theme }) => theme.colors.fgColor};
`;

export const NavWrapper = styled.div`
  background: ${({ theme }) => theme.colors.navBgColor};
  color: ${({ theme }) => theme.colors.fgColor};
  height: ${({ theme }) => theme.sizes.mobileNavbarHeight};
  padding: 0px;
  display: flex;
  align-items: center;
  padding: 24px 30px;
  top: 0;
  z-index: 1000;
  position: fixed;
  width: calc(100vw - (100vw - 100%));
  transition: background 0.5s;
  backdrop-filter: blur(0px);

  &.bg-color {
    background: ${({ theme }) => theme.colors.bgColor};
  }

  &.opaque {
    background: rgb(0, 0, 0, 0.75);
    backdrop-filter: blur(6px);
    color: ${({ theme }) => theme.colors.bgColor};

    ${BackButton} {
      color: ${({ theme }) => theme.colors.bgColor};
    }
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    background: ${({ theme }) => theme.colors.navBgColor};
    backdrop-filter: blur(6px);
    height: ${({ theme }) => theme.sizes.navbarHeight};

    &.opaque {
      background: ${({ theme }) => theme.colors.navBgColor};
      backdrop-filter: blur(6px);
      color: ${({ theme }) => theme.colors.fgColor};
    }
  }

  & button {
    margin: 0px 3px 0px 3px;
  }
`;

export const NavConnectButton = styled(Button)`
  font-size: 18px;
  padding: 8px 16px;
`;
