import { initializeApp, FirebaseApp } from "firebase/app";
import { getAnalytics, Analytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEKEY || "",
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "default-auth-domain",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "default-project-id",
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "default-storage-bucket",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID || "",
  appId: process.env.REACT_APP_FIREBASE_APPID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID || "",
};

let analytics: Analytics | undefined;

try {
  const app: FirebaseApp = initializeApp(firebaseConfig);
  if (
    firebaseConfig.apiKey &&
    firebaseConfig.messagingSenderId &&
    firebaseConfig.appId
  ) {
    analytics = getAnalytics(app);
  } else {
    console.warn(
      "Firebase configuration is incomplete. Analytics will not be initialized."
    );
  }
} catch (error) {
  console.error("Firebase initialization error:", error);
}

export { analytics, logEvent };
