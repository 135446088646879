export const abi = [
  {
    inputs: [
      { internalType: "address", name: "_collectorNFT1155", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "AdminApproved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "AdminRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "creatorContract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "instanceId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "initializer",
        type: "address",
      },
    ],
    name: "ClaimInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "creatorContract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "instanceId",
        type: "uint256",
      },
    ],
    name: "ClaimMint",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "creatorContract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "instanceId",
        type: "uint256",
      },
    ],
    name: "ClaimUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "_paymentReceivers",
    outputs: [
      { internalType: "address", name: "paymentAddress", type: "address" },
      { internalType: "uint256", name: "paymentSplit", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      { internalType: "address[]", name: "recipients", type: "address[]" },
      { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
    ],
    name: "airdrop",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "admin", type: "address" }],
    name: "approveAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAdmins",
    outputs: [{ internalType: "address[]", name: "admins", type: "address[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "instanceId", type: "uint256" }],
    name: "getClaim",
    outputs: [
      {
        components: [
          { internalType: "uint32", name: "total", type: "uint32" },
          { internalType: "uint32", name: "totalMax", type: "uint32" },
          { internalType: "uint32", name: "walletMax", type: "uint32" },
          { internalType: "uint48", name: "startDate", type: "uint48" },
          { internalType: "uint48", name: "endDate", type: "uint48" },
          { internalType: "string", name: "location", type: "string" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "cost", type: "uint256" },
        ],
        internalType: "struct ICollectorV1.Claim",
        name: "claim",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getClaimForToken",
    outputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      {
        components: [
          { internalType: "uint32", name: "total", type: "uint32" },
          { internalType: "uint32", name: "totalMax", type: "uint32" },
          { internalType: "uint32", name: "walletMax", type: "uint32" },
          { internalType: "uint48", name: "startDate", type: "uint48" },
          { internalType: "uint48", name: "endDate", type: "uint48" },
          { internalType: "string", name: "location", type: "string" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "cost", type: "uint256" },
        ],
        internalType: "struct ICollectorV1.Claim",
        name: "claim",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "minter", type: "address" },
      { internalType: "uint256", name: "instanceId", type: "uint256" },
    ],
    name: "getTotalMints",
    outputs: [{ internalType: "uint32", name: "", type: "uint32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      {
        components: [
          { internalType: "uint32", name: "totalMax", type: "uint32" },
          { internalType: "uint32", name: "walletMax", type: "uint32" },
          { internalType: "uint48", name: "startDate", type: "uint48" },
          { internalType: "uint48", name: "endDate", type: "uint48" },
          { internalType: "string", name: "location", type: "string" },
          { internalType: "uint256", name: "cost", type: "uint256" },
        ],
        internalType: "struct ICollectorV1.ClaimParameters",
        name: "claimParameters",
        type: "tuple",
      },
      {
        internalType: "address[]",
        name: "_paymentAddresses",
        type: "address[]",
      },
      { internalType: "uint256[]", name: "_paymentSplits", type: "uint256[]" },
    ],
    name: "initializeClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "admin", type: "address" }],
    name: "isAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "receiver", type: "address" },
      { internalType: "uint256", name: "instanceId", type: "uint256" },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "admin", type: "address" }],
    name: "revokeAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      {
        components: [
          { internalType: "uint32", name: "totalMax", type: "uint32" },
          { internalType: "uint32", name: "walletMax", type: "uint32" },
          { internalType: "uint48", name: "startDate", type: "uint48" },
          { internalType: "uint48", name: "endDate", type: "uint48" },
          { internalType: "string", name: "location", type: "string" },
          { internalType: "uint256", name: "cost", type: "uint256" },
        ],
        internalType: "struct ICollectorV1.ClaimParameters",
        name: "claimParameters",
        type: "tuple",
      },
    ],
    name: "updateClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      {
        internalType: "address[]",
        name: "_paymentAddresses",
        type: "address[]",
      },
      { internalType: "uint256[]", name: "_paymentSplits", type: "uint256[]" },
    ],
    name: "updatePaymentReceivers",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "instanceId", type: "uint256" },
      { internalType: "string", name: "location", type: "string" },
    ],
    name: "updateTokenURIParams",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "receiver", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;
