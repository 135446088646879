import { ProgressBarProps } from "./types";
import { ProgressBarFill, ProgressBarBackground } from "./styled";

const ProgressBar = ({ progress, index, children }: ProgressBarProps) => (
  <ProgressBarBackground>
    <ProgressBarFill
      initial={{ width: 0 }}
      animate={{ width: `${progress}%` }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    />
  </ProgressBarBackground>
);

export default ProgressBar;
