import styled, { DefaultTheme } from "styled-components";

import {
  ColorType,
  AlignType,
  SeparatorType,
  ContainerProps,
  ContainerContentProps,
  ContainerSeparatorProps,
  HorizontalStackProps,
  EqualHStackProps,
  VerticalStackProps,
  DeviceShowType,
} from "./types";

export const Container = styled.div<
  ContainerProps & { $colorType?: ColorType; $fullHeight?: boolean }
>(({ $colorType: type = ColorType.PRIMARY, $fullHeight = false, theme }) => {
  const breakpointMd = `@media ${theme.breakpoints.md}`;

  return {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: `${theme.spacing.x1} ${theme.spacing.x3}`,
    overflow: "hidden",
    [breakpointMd]: {
      padding: `${theme.spacing.x10} ${theme.spacing.x10} ${theme.spacing.x5} ${theme.spacing.x10}`,
      height: $fullHeight ? "100vh" : "auto",
    },
    ...getColorTypeStyle(type, theme),
  };
});

export const ContainerContent = styled.div<
  ContainerContentProps & {
    $align?: string;
    $alignType?: AlignType;
    $deviceShowType?: DeviceShowType;
  }
>`
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "block"};
  width: 100%;
  z-index: 1;
  max-width: 1440px;
  text-align: ${({ $alignType = AlignType.LEFT }) => $alignType};
  align-content: ${({ $align = "normal" }) => $align};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "block"};
  }
`;

export const ContainerFlexContent = styled.div<
  ContainerContentProps & {
    $alignType?: AlignType;
    $deviceShowType?: DeviceShowType;
  }
>`
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "flex"};
  z-index: 1;
  max-width: 1080px;
  align-items: center;
  justify-content: ${({ $alignType = AlignType.LEFT }) =>
    $alignType === AlignType.LEFT
      ? "flex-start"
      : $alignType === AlignType.RIGHT
      ? "flex-end"
      : "center"};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "flex"};
  }
`;

export const ContainerSeparator = styled.div<
  ContainerSeparatorProps & { $separatorType?: SeparatorType }
>(({ $separatorType = SeparatorType.FULL, theme }) => ({
  position: "absolute",
  bottom: 0,
  zIndex: 0,
  ...getSeparatorTypeStyle($separatorType, theme),
}));

export const HorizontalStack = styled.div<
  HorizontalStackProps & {
    $isWrap?: boolean;
    $noMobileCollapse?: boolean;
  }
>`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.x2};
  display: flex;
  flex-wrap: ${({ $noMobileCollapse = false }) => {
    if ($noMobileCollapse) {
      return "nowrap;";
    } else {
      return "wrap;";
    }
  }}
  @media ${({ theme }) => theme.breakpoints.md} {
    flex-wrap: ${({ $isWrap = false }) => ($isWrap ? `wrap` : `nowrap`)};
    }
  }
`;

export const EqualHStack = styled(HorizontalStack)<
  EqualHStackProps & { $noMobileCollapse?: boolean }
>`
  justify-content: center;
  gap: ${({ theme, gapSize = theme.spacing.x2 }) => gapSize};

  > * {
    width: ${({
      theme,
      columns = 3,
      $noMobileCollapse = false,
      gapSize = theme.spacing.x2,
    }) => {
      if ($noMobileCollapse) {
        const numGaps = columns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${columns})`;
      } else {
        return "100%";
      }
    }};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    > * {
      width: ${({ theme, columns = 3, gapSize = theme.spacing.x2 }) => {
        const halfColumns = Math.ceil(columns / 2);
        const numGaps = halfColumns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${halfColumns})`;
      }};
    }
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    > * {
      width: ${({ theme, columns = 3, gapSize = theme.spacing.x2 }) => {
        const numGaps = columns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${columns})`;
      }};
    }
  }
`;

export const VerticalStack = styled.div<
  VerticalStackProps & {
    $alignType?: AlignType;
  }
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $alignType = AlignType.LEFT }) =>
    $alignType === AlignType.LEFT
      ? "flex-start"
      : $alignType === AlignType.RIGHT
      ? "flex-end"
      : "center"};
  margin: auto;

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: ${({ align = "center" }) => align};
    margin: 0;
  }
`;

export const Spacer = styled.div<{ $deviceShowType?: DeviceShowType }>`
  width: 100%;
  height: ${({ theme }) => theme.sizes.navbarHeight};
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "flex"};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "flex"};
  }
`;

function getColorTypeStyle(colorType: ColorType, theme: DefaultTheme) {
  switch (colorType) {
    case ColorType.PRIMARY:
      return {
        backgroundColor: theme.colors.transparent,
        color: theme.colors.fgColor,
      };
    case ColorType.SECONDARY:
      return {
        backgroundColor: theme.colors.secondaryBgColor,
        color: theme.colors.secondaryFgColor,
      };
  }
}

function getSeparatorTypeStyle(
  separatorType: SeparatorType,
  theme: DefaultTheme
) {
  switch (separatorType) {
    case SeparatorType.HERO:
      const breakpointMd: string = `@media ${theme.breakpoints.md}`;
      return {
        background: theme.colors.bgColor,
        height: "24px",
        width: "100%",
        borderRadius: "24px 24px 0 0",
        [breakpointMd]: {
          background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, ${theme.colors.bgColor} 100%)`,
          height: "100px",
          borderRadius: "0",
        },
      };
    case SeparatorType.FULL:
      return {
        background: theme.colors.accent,
        height: "5px",
        width: "100%",
      };
    case SeparatorType.PARTIAL:
      return {
        background: theme.colors.accent,
        height: "3px",
        width: "33%",
      };
    default:
      return {};
  }
}
