import styled from "styled-components";
import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { ModalButton } from "@components/Buttons";
import { analytics, logEvent } from "../../../firebase";
import { PinataSDK } from "pinata";

interface BenefitsModalProps {
  tag: string;
  isOpen: boolean;
  onClose: () => void;
  benefitsCID: string;
}

const WallpaperBlurb = styled.div`
  background: ${({ theme }) => theme.colors.royalBlue};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: 700;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
`;

const BenefitsModal = ({
  isOpen,
  onClose,
  benefitsCID,
  tag,
}: BenefitsModalProps) => {
  const handleDownload = () => {
    if (analytics) {
      logEvent(analytics, "wallpapers_downloaded", { edition: tag });
    }
    const pinata = new PinataSDK({
      pinataJwt: process.env.REACT_APP_PINATA_JWT,
      pinataGateway: "collectr-private.mypinata.cloud",
    });
    
    pinata.gateways.createSignedURL({
      cid: benefitsCID,
      expires: 30, // Number of seconds link is valid for
    }).then((url: string) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = `${tag}-wallpapers.zip`
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((err) => {
      console.log(err)
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Exclusive Wallpapers">
      <ContainerContent>
        <HorizontalStack>
          <VerticalStack>
            <WallpaperBlurb>
              Desktop - Standard 16:9 + Ultrawide 21:9
            </WallpaperBlurb>
            <WallpaperBlurb>Mobile - iPhone + Android</WallpaperBlurb>
            <WallpaperBlurb>Smartwatch</WallpaperBlurb>
            <ModalButton onClick={handleDownload}>download all (.zip)</ModalButton>
          </VerticalStack>
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default BenefitsModal;
