const baseColors = {
  white: "#FFFFFF",
  offWhite: "#F5F5F5",
  black: "#000000",
  transparent: "transparent",

  pink: "#FF80B8",
  lighterBlue: "#ade2ff",
  lightBlue: "#1FBCFF",
  lessRoyalBlue: "#04458d",
  royalBlue: "#00438F",
  shadowedMidnight: "#041426",
  darkBlue: "#008ecf",
  deepOceanBlue: "#0D3159",

  grey20: "#F4F5F6",
  grey40: "#E6E6E6",
  grey60: "#949BA2",
  grey90: "#252930",

  teal30: "#8298b0",
  teal40: "#8298b0",

  greyBlue30: "#8ea2b7",
};

const themeColors = {
  ...baseColors,
  primary: baseColors.lightBlue,
  primaryActive: baseColors.darkBlue,
  primaryDisabled: baseColors.grey90,

  secondary: baseColors.white,
  secondaryActive: baseColors.white,
  secondaryDisabled: baseColors.white,

  bgColor: baseColors.shadowedMidnight,
  secondaryBgColor: baseColors.deepOceanBlue,
  navBgColor: "rgb(10, 67, 143, .95)",

  fgColor: baseColors.offWhite,
  secondaryFgColor: baseColors.grey60,

  accent: baseColors.grey40,
};

const colors = {
  ...themeColors,
};

export default colors;
