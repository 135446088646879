import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import theme from "../../theme/theme";
import { useLatestEdition } from "@context/EditionsProvider";
import {
  StyledNavItem,
  HamburgerIcon,
  NavWrapper,
  // SoonTag,
  BackButton,
  NavConnectButton,
} from "./styled";
import { Props } from "./types";
import {
  AlignType,
  DeviceShowType,
  ContainerContent,
  ContainerFlexContent,
  HorizontalStack,
  EqualHStack,
} from "@components/Layout";
import { LogoTypeImage } from "@components/Image";
import { ChevronLeft } from "@styled-icons/octicons";

const IS_PRELAUNCH = process.env.REACT_APP_IS_PRELAUNCH === "true";

interface LinksContainerProps {
  $isOpen: boolean;
}

const LinksContainer = styled.div<LinksContainerProps>`
  display: ${(props: LinksContainerProps) => (props.$isOpen ? "flex" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondaryBgColor};
  padding: 30px 0;

  a {
    padding: 8px 0;
  }

  span {
    padding: 8px 0;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    position: relative;
    top: 0;
    width: auto;
    flex-direction: row;
    background: none;
    padding: 0;

    a {
      padding-left: 44px;
    }

    span {
      padding-left: 44px;
    }
  }
`;

const NavBar = ({ className, ...props }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [hamburgerOpened, setHamburger] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  let pathTokens = pathname.split("/").filter((token) => token);
  const validMonths = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
    "jan",
    "feb",
    "mar",
    "apr",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < theme.breakpointSizes.md);
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const latestEdition = useLatestEdition();

  const toggleHamburger = () => setHamburger(!hamburgerOpened);

  const closeHamburger = () => setHamburger(false);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleGoBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (document.referrer.includes(window.location.origin)) {
      // The document.referrer is within your application, navigate back
      navigate(-1);
    } else {
      // Otherwise, navigate to a default route (e.g., home page)
      navigate("/");
    }
  };

  const handleNavigateEdition = () => {
    if (latestEdition) {
      const { year, month } = latestEdition;
      navigate(`/${year}/${month}`);
      closeHamburger();
    }
  };

  if (
    isMobile &&
    pathTokens.length === 2 &&
    /^\d{4}$/.test(pathTokens[0]) && // Check if the first token is a valid year
    validMonths.includes(pathTokens[1]) // Check if the second token is a valid month name
  ) {
    return (
      <NavWrapper>
        <ContainerContent>
          <BackButton href="" onClick={handleGoBack}>
            <ChevronLeft />
          </BackButton>
        </ContainerContent>
        <ConnectButton
          label="CONNECT"
          accountStatus="address"
          chainStatus="none"
        />
      </NavWrapper>
    );
  } else {
    return (
      <NavWrapper>
        <EqualHStack>
          <ContainerContent $alignType={AlignType.CENTER} $align="center">
            <HorizontalStack>
              <LogoTypeImage
                src="/images/combomark.svg"
                onClick={(e: React.MouseEvent<HTMLImageElement>) => {
                  e.preventDefault();
                  handleGoHome();
                }}
                width="100"
                height="50"
              />
            </HorizontalStack>
          </ContainerContent>
          {IS_PRELAUNCH ? (
            <>
              <LinksContainer $isOpen={false}></LinksContainer>
              <ContainerFlexContent
                $alignType={AlignType.RIGHT}
                $deviceShowType={DeviceShowType.DESKTOPONLY}
              ></ContainerFlexContent>
            </>
          ) : (
            <>
              <LinksContainer $isOpen={hamburgerOpened}>
                <StyledNavItem
                  url="/"
                  internal
                  onClick={closeHamburger}
                  className={pathname === "/" ? "highlight" : ""}
                >
                  Home
                </StyledNavItem>
                <StyledNavItem
                  url="#"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    handleNavigateEdition();
                  }}
                  className={
                    pathname.startsWith(
                      `/${latestEdition?.year}/${latestEdition?.month}`
                    )
                      ? "highlight"
                      : ""
                  }
                >
                  Edition
                </StyledNavItem>
                <StyledNavItem
                  url="/gallery"
                  internal
                  onClick={closeHamburger}
                  className={pathname === "/gallery" ? "highlight" : ""}
                >
                  Gallery
                </StyledNavItem>
                {/* <StyledNavItem
                  url="#social"
                  internal
                  onClick={closeHamburger}
                  disabled
                >
                  Social
                  <SoonTag>SOON</SoonTag>
                </StyledNavItem> */}
              </LinksContainer>
              <ContainerFlexContent
                $alignType={AlignType.RIGHT}
                $deviceShowType={DeviceShowType.DESKTOPONLY}
              >
                <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                  }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== "loading";
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === "authenticated");

                    return (
                      <div
                        {...(!ready && {
                          "aria-hidden": true,
                          style: {
                            opacity: 0,
                            pointerEvents: "none",
                            userSelect: "none",
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (
                              <NavConnectButton
                                onClick={openConnectModal}
                                type="button"
                              >
                                CONNECT
                              </NavConnectButton>
                            );
                          }

                          if (chain.unsupported) {
                            return (
                              <NavConnectButton
                                onClick={openChainModal}
                                type="button"
                              >
                                switch network
                              </NavConnectButton>
                            );
                          }

                          return (
                            <div style={{ display: "flex", gap: 12 }}>
                              <NavConnectButton
                                onClick={openAccountModal}
                                type="button"
                              >
                                {account.displayName}
                              </NavConnectButton>
                            </div>
                          );
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              </ContainerFlexContent>
            </>
          )}
        </EqualHStack>
        {IS_PRELAUNCH ? <></> : <HamburgerIcon onClick={toggleHamburger} />}
      </NavWrapper>
    );
  }
};

export default NavBar;
