import ReactDOM from "react-dom";

import { X } from "@styled-icons/octicons";

import { ModalProps } from "./types";
import {
  ModalOverlay,
  ModalContent,
  ModalImage,
  ModalTitle,
  ModalPillTitle,
  CloseButton,
  CloseButtonWithBg,
  ContentWrapper,
} from "./styled";

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  overflowY = true,
  padding,
  image,
}: ModalProps) => {
  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    if (!target.closest("#modal-content")) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleClickOutside}>
      <ModalContent id="modal-content">
        {image ? (
          <>
            <ModalImage imgUrl={image}>
              <>
                {title && <ModalPillTitle>{title}</ModalPillTitle>}
                <CloseButtonWithBg onClick={onClose}>
                  <X />
                </CloseButtonWithBg>
              </>
            </ModalImage>
            <br />
          </>
        ) : (
          <>
            {title && <ModalTitle>{title}</ModalTitle>}
            <CloseButton onClick={onClose}>
              <X />
            </CloseButton>
          </>
        )}
        <ContentWrapper padding={padding} overflowY={overflowY}>{children}</ContentWrapper>
      </ModalContent>
    </ModalOverlay>,
    document.getElementById("root") as HTMLElement
  );
};

export default Modal;
