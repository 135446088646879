import { motion } from "framer-motion";
import styled from "styled-components";

export const ProgressBarText = styled.div`
  color: ${({ theme }) => theme.colors.bgColor};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: ${({ theme }) => theme.fontSizes.xlarge};
  text-align: center;

  strong {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
    line-height: ${({ theme }) => theme.fontSizes.xlarge};
    font-weight: 1000;
  }
`;

export const ProgressBarBackground = styled.div`
  border-radius: 9999px;
  height: ${({ theme }) => theme.fontSizes.xsmall};
  background-color: ${({ theme }) => theme.colors.grey40};
  border-radius: 50px;
  overflow: hidden;
`

export const ProgressBarFill = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
  border-radius: 9999px;
`