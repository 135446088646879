import styled, { css } from "styled-components";

import { VerticalStack } from "@components/Layout";
import {
  ProgressBarFill,
  ProgressBarText,
  ProgressBarBackground,
} from "@components/ProgressBar";

import { CardImageProps } from "./types";

export const CardImageWrapper = styled.div`
  height: 75%;
  min-height: 75%;
  max-height: 75%;
  width: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 82.5%;
    min-height: 82.5%;
    max-height: 82.5%;
    width: 100%;
  }
`;

export const CardImage = styled.div<CardImageProps>`
  background-image: url(${(props) => props.imgurl});
  background-size: cover;
  background-position: center;
  border-radius: ${({ theme }) => theme.radii.x1};
  height: 100%;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: 0;
  }
`;

export const CardContent = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`;

export const CardLine = styled.div`
  display: flex;
  width: 100%;
`;

export const CardHeadline = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 8px;
  font-weight: 700;
  justify-content: top;
  text-transform: uppercase;
`;

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.fgColor};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: ${({ theme }) => theme.fontSizes.large};
  font-weight: 700;
  justify-content: top;
`;

export const CardParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-align: center;
  max-width: 350px;
  line-height: 24px;
`;

export const TimeLeft = styled.div<{ $isLive?: boolean }>`
  position: absolute;
  top: ${({ theme }) => theme.spacing.x2};
  left: ${({ theme }) => theme.spacing.x2};
  padding: 0 ${({ theme }) => theme.spacing.x2};
  height: ${({ theme }) => theme.fontSizes.xlarge};
  background-color: ${({ theme, $isLive }) =>
    $isLive ? theme.colors.bgColor : "#30323c"};
  opacity: 0.8;
  border-radius: 50px;
  overflow: hidden;
  color: ${({ theme, $isLive }) =>
    $isLive ? theme.colors.bgColor : theme.colors.fgColor};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: ${({ theme }) => theme.fontSizes.xlarge};
  text-align: center;

  ${({ $isLive }) =>
    $isLive
      ? css`
          &:after {
            content: "";
            position: absolute;
            top: 7px;
            right: 7px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: red;
          }
        `
      : ""}
`;

export const PriceContainer = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: 1000;
  line-height: ${({ theme }) => theme.fontSizes.xlarge};
`;

export const CreatorContainer = styled.div`
  margin-left: auto;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  img {
    position: relative;
    top: -3px;
  }
`;

export const BaseCardStack = styled(VerticalStack)`
  position: relative;
  background: ${({ theme }) => theme.colors.fgColor};
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 8px 0px rgba(88, 105, 148, 0.15);
  height: 380px;
  overflow: hidden;
  transition: background 0.5s;
  align-items: start;

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: ${({ theme }) => theme.radii.x2};
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 24px rgba(88, 105, 148, 0.25);
  }
`;

export const InfoCardStack = styled(BaseCardStack)`
  height: auto;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.secondaryBgColor} 0%,
    ${({ theme }) => theme.colors.secondaryBgColor} 80px,
    ${({ theme }) => theme.colors.fgColor} 80px,
    ${({ theme }) => theme.colors.fgColor} 100%
  );
  color: ${({ theme }) => theme.colors.bgColor};
  width: 100%;
  border-radius: 10px;
  border: 1px solid #21456d;
  box-shadow: 0px 2px 24px rgba(88, 105, 148, 0.25);
  padding-bottom: 16px;

  @media ${({ theme }) => theme.breakpoints.md} {
    border-radius: ${({ theme }) => theme.radii.x2};
  }

  ${CardTitle} {
    color: ${({ theme }) => theme.colors.fgColor};
  }

  ${CardContent} {
    background: ${({ theme }) => theme.colors.fgColor};
  }

  ${CreatorContainer} {
    color: ${({ theme }) => theme.colors.bgColor};
  }

  ${CardImage} {
    transform: perspective(500px) translateZ(25px);
  }

  ${ProgressBarBackground} {
    background-color: ${({ theme }) => theme.colors.bgColor};
  }

  ${ProgressBarFill} {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ${ProgressBarText} {
    color: ${({ theme }) => theme.colors.fgColor};
  }

  ${PriceContainer} {
    color: ${({ theme }) => theme.colors.bgColor};
  }
`;

export const ArtCardStack = styled(BaseCardStack)`
  background: ${({ theme }) => theme.colors.secondaryBgColor};
  height: 544px;

  div {
    transition: all 0.5s;
  }

  h3 {
    transition: color 0.5s;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    &:active {
      position: relative;
      top: 2px;
      left: 2px;
      box-shadow: none;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary};

    ${CardHeadline} {
      color: ${({ theme }) => theme.colors.bgColor};
    }

    ${CardTitle} {
      color: ${({ theme }) => theme.colors.bgColor};
    }

    ${CreatorContainer} {
      color: ${({ theme }) => theme.colors.bgColor};
    }

    ${CardImage} {
      transform: perspective(500px) translateZ(15px);
    }

    ${ProgressBarBackground} {
      background-color: ${({ theme }) => theme.colors.bgColor};
    }

    ${ProgressBarFill} {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    ${ProgressBarText} {
      color: ${({ theme }) => theme.colors.fgColor};
    }

    ${PriceContainer} {
      color: ${({ theme }) => theme.colors.bgColor};
    }
  }
`;
