import { HTMLProps } from "react";

export interface LinkProps extends HTMLProps<HTMLAnchorElement> {
  href: string;
  className?: string;
  children?: React.ReactNode;
  svgWidth?: number;
  linkType?: Type;
  disabled?: boolean;
  internal?: boolean;
}

export enum Type {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BUTTON = "button",
  ICON = "icon",
}
