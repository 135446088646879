import { editions_data } from "@apis/editions";

// Finds the closest index in the Array that the number provided is greater than
export function getClosestIndex(arr: Array<number>, num: number) {
  return Array.isArray(arr)
    ? arr
        .concat(num)
        .sort((a, b) => a - b)
        .indexOf(num)
    : 0;
}

export function truncatedAddress(address: string) {
  return `${address?.substr(0, 4)}...${address?.substr(-4)}`;
}

export function truncatedBalance(balance: string) {
  return Math.ceil(parseFloat(balance) * 100) / 100;
}

export function titleize(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function unixEpochToDateTime(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedYear = date.toLocaleString([], { year: "numeric" });
  const formattedMonth = date.toLocaleString([], { month: "2-digit" });
  const formattedDay = date.toLocaleString([], { day: "2-digit" });
  const formattedHour = date.toLocaleString([], {
    hour: "numeric",
    minute: "2-digit",
  });
  // const formattedMin = date.toLocaleString([], {minute: '2-digit'})

  // Get a DateTimeFormat object for the user's current culture (via undefined)
  // Ask specifically for the long-form of the time zone name in the options
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "short" });

  // Format the date to parts, and pull out the value of the time zone name
  const brokenPart = dtf
    ?.formatToParts(date)
    .find((part) => part.type === "timeZoneName");
  const result = brokenPart?.value;

  return `${formattedYear}.${formattedMonth}.${formattedDay} ${formattedHour} ${result}`;
}

export function unixEpochToDate(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedYear = date.toLocaleString([], { year: "numeric" });
  const formattedMonth = date.toLocaleString([], { month: "2-digit" });
  const formattedDay = date.toLocaleString([], { day: "2-digit" });

  return `${formattedYear}.${formattedMonth}.${formattedDay}`;
}

export function unixEpochToTime(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedDay = date.toLocaleString([], { day: "2-digit" });
  const formattedHour = date.toLocaleString([], { hour: "2-digit" });
  // const formattedMin = date.toLocaleString([], {minute: '2-digit'})

  return `${formattedDay}:${formattedHour} ${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
}

// Given end time, generates human readable "time left" based on current time
export function formatTimeLeft(endTime: number): string {
  const now = Math.floor(Date.now() / 1000);
  const timeLeft = endTime - now;

  if (timeLeft <= 0) {
    return "Concluded";
  }

  const days = Math.floor(timeLeft / (24 * 60 * 60));
  const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeLeft % (60 * 60)) / 60);

  let timeLeftString = "";
  if (days === 1) {
    timeLeftString += `${days} day `;
  } else if (days > 0) {
    timeLeftString += `${days} days `;
  }
  if ((days === 1 || days === 0) && hours > 0) {
    timeLeftString += `${hours} hours `;
  } else if (days === 0 && hours < 1 && minutes > 0) {
    timeLeftString += `${minutes} minutes `;
  }

  return timeLeftString;
}

// Converts ETH amount to wei amount (for sending as value in tx)
export function ethToWei(ethString: string) {
  const eth = parseFloat(ethString);
  if (isNaN(eth)) {
  }
  const weiPerEth = 10 ** 18;
  return eth * weiPerEth;
}

type MonthMap = {
  [key: string]: string;
};

export const monthMapShortToLong: MonthMap = {
  jan: "january",
  feb: "february",
  mar: "march",
  apr: "april",
  may: "may",
  jun: "june",
  jul: "july",
  aug: "august",
  sep: "september",
  oct: "october",
  nov: "november",
  dec: "december",
};

export const monthMapLongToShort: MonthMap = {
  january: "jan",
  february: "feb",
  march: "mar",
  april: "apr",
  may: "may",
  june: "jun",
  july: "jul",
  august: "aug",
  september: "sep",
  october: "oct",
  november: "nov",
  december: "dec",
};

// Checks if the provided year and month exist in the editions data.
export const isValidYearAndMonth = (year: number, month: string): boolean => {
  return editions_data.some(
    (edition) => edition.year === year && edition.month === month
  );
};
