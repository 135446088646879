import { StyledButton, StyledButtonText } from "./styled";
import { ButtonProps, Type } from "./types";

const ButtonBase = ({
  className,
  onClick,
  text,
  buttonType = Type.PRIMARY,
  children,
  disabled,
  padding,
  fullWidth,
}: ButtonProps) => (
  <StyledButton
    className={className}
    onClick={onClick}
    $buttonType={buttonType}
    disabled={disabled}
    padding={padding}
    fullWidth={fullWidth}
  >
    {children}
    <StyledButtonText>{text}</StyledButtonText>
  </StyledButton>
);

export default ButtonBase;
