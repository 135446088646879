import colors from "./colors";
import {
  size as breakpointSizes,
  breakpoints,
  maxBreakpoints,
} from "./breakpoints";

const theme = {
  fontWeights: {
    normal: 400,
  },
  fontSizes: {
    xxsmall: "12px",
    xsmall: "14px",
    small: "18px",
    normal: "20px",
    large: "24px",
    xlarge: "32px",
    headline: "64px",
    title: "40px",
    subtitle: "24px",
  },
  spacing: {
    x1: "8px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x5: "40px",
    x8: "64px",
    x10: "80px",
    x12: "96px",
  },
  radii: {
    x1: "8px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x8: "64px",
    x10: "80px",
  },
  colors,
  sizes: {
    navbarHeight: "56px",
    mobileNavbarHeight: "56px",
  },
  breakpointSizes,
  breakpoints,
  maxBreakpoints,
};

export default theme;
