import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics, logEvent } from "../firebase";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const logPageView = () => {
      if (analytics) {
        logEvent(analytics, "page_view", {
          page_path: location.pathname + location.search,
        });
      }
    };

    logPageView();
  }, [location]);
};

export default usePageTracking;
