import styled from "styled-components";
import {
  CardImageWrapper,
  CardImage,
  CardContent,
  CardTitle,
  InfoCardStack,
} from "./styled";
import { InfoCardProps } from "./types";

const InfoCard = ({ imgurl, title, children }: InfoCardProps) => {
  return (
    <InfoCardStack spacing={2}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {imgurl && (
        <CardImageWrapper>
          <CardImage imgurl={imgurl} />
        </CardImageWrapper>
      )}
      <CardContent>{children}</CardContent>
    </InfoCardStack>
  );
};

export default InfoCard;

const CardHeader = styled.div`
  background: ${({ theme }) => theme.colors.lessRoyalBlue};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.x2};

  ${CardTitle} {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  svg {
    margin-bottom: 4px;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
